<app-layout></app-layout>
<div class="content">

  <!-- <select name="zone" id="">
        <option value="1">Zone1</option>
        <option value="1">Zone1</option>
        <option value="1">Zone1</option>
    </select> -->
  <div class="container-fluid">
    <div class="row">

      <form [formGroup]="coordForm">
        <!-- <div class="col-lg-3">
        <div class="form-group">
          <label for="description">Select Zone </label>
          <mat-form-field appearance="fill">
            <mat-select placeholder="Choose Zone">
              <mat-option [value]="z._id" *ngFor="let z of zones">{{z.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->
        <div class="col-lg-3">
          <div class="form-group">
            <label for="description">Select Sub-Zone/Sub-Location <span class="error-asterisk">*</span></label>
            <mat-form-field appearance="fill">
              <mat-select placeholder="Choose Sub-Zone/Sub-Location" (selectionChange)="onSelect($event.value)"
                formControlName="subzone">
                <mat-option [value]="z" *ngFor="let z of subZones">{{z.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="alert alert-danger" *ngIf="submitted && f.subzone.errors">
              <span *ngIf="f.subzone.errors.required">
                
                <div *ngIf="lang=='eng'">Sub-Zone/Sub-Location is required.</div>
                <div *ngIf="lang=='ara'">المنطقة الفرعية / الموقع الفرعي مطلوب</div>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="title">Color Code <span class="error-asterisk">*</span></label>
            <input type="text" class="form-control" placeholder=" Type Here" id="title" formControlName="color">
            <div class="alert alert-danger" *ngIf="submitted && f.color.errors">
              <span *ngIf="f.color.errors.required">
                <div *ngIf="lang=='eng'">Color Code is required.</div>
                <div *ngIf="lang=='ara'">كود اللون مطلوب.</div>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <button class="btn btn-primary submit" (click)="updateSubZone()">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div id="map"></div>
</div>