import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ChatApiService } from '../chat-api.service';
import { ChatService } from '../chat.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-request',
  templateUrl: './event-request.component.html',
  styleUrls: ['./event-request.component.scss']
})
export class EventRequestComponent implements OnInit {
  [x: string]: any;

  modalRef: BsModalRef;
  message: string;
  chatRequests: any;
  page: number;
  searchSubmit: any;
  itemsPerPage: any = 6;
  totalCount: any;
  notFound: boolean = false;
  requestCount: number = 0;
  currentPage: number = 4;
  smallnumPages: number = 0;

  constructor(
    private modalService: BsModalService,
    private _header: HeaderService,
    private chatService: ChatService,
    private service: ChatApiService,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    this.requestStatus = 'Test';
    this.currentUser = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(this.currentUser);
    this._header.EventRequestHeading.next(true);

    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      // this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
      if (this.page) {
        this.currentPage = this.page;
      }
    });
    // this.getEventRequests();

    this.getEventRequests(this.page, this.itemsPerPage);

    this.getSupportRequest()
  }
  ngOnDestroy() {
    this._header.EventRequestHeading.next(false);
  }

  getSupportRequest() {
    this.chatService.getSupportRequest().subscribe((res: any) => {
      this.requestCount = this.requestCount + res;
    })
  }


  getEventRequests(page, limit) {
    this.service.requestsForZone(
      {
        "page": page, "pageLimit": limit, "cType": "request", "cId": "", "search": ""
      }
    ).subscribe((res: any) => {
      this.chatRequests = res.result;
      this.totalCount = res.totalCount;

    })
  }

  refreshChat() {
    this.service.requestChatLists(
      {
        "page": 1, "pageLimit": this.itemsPerPage
      }
    ).subscribe((res: any) => {
      this.chatRequests = res.result;
      this.totalCount = res.totalCount;
      this.requestCount = 0;

    })
  }


  pageChanged(event: PageChangedEvent): void {
    this.page = event.page;
    if (!this.searchSubmit) {
      this.getEventRequests(event.page, event.itemsPerPage)
    }
    this.searchSubmit = false;

  }
  accept(id: any) {
    // alert('accept');
    this.service.updateEventRequest({
      cId: id, status: 2, cType: 'request'
    }).subscribe((res: any) => {
      if (res.status == true) {
        this.getEventRequests(this.page, this.itemsPerPage);
      } else {

      }
    })
  }

  reject(id: any) {
    this.service.updateEventRequest({
      cId: id, status: 3, cType: 'request'
    }).subscribe((res: any) => {
      if (res.status == true) {
        this.getEventRequests(this.page, this.itemsPerPage);
      } else {

      }
    })
  }

  // getEventRequests() {

  // }
}
