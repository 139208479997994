import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class RequestService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) {

  }

  getRequests(data: any) {
    return this.http.post(this.url + 'category/list', data);
  }

  createRequest(data: any) {
    return this.http.post(this.url + 'category/create', data);
  }

  // getEvents(data: any) {
  //     return this.http.post(this.url + 'event/list', data);
  // }

  updateRequest(data: any) {
    return this.http.patch(this.url + 'category/update', data);
  }

  deleteRequest(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}), body: data
    };
    return this.http.delete(this.url + 'category/delete', httpOptions);
  }
}