import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, observable } from 'rxjs';
// import { FunctionsService } from '../functions.service';
import { environment } from 'src/environments/environment';
// import { Chatproperties } from './chatproperties';
import { io } from 'socket.io-client';

import { Socket } from 'ngx-socket-io';


@Injectable({
  providedIn: 'root'
})
export class ChatService {
  userLoginDetail: any;// jsFunctions - user login details
  // private socket;
  url: any;
  constructor(
    private socket: Socket,
    private http: HttpClient,
    // private _jsFunctions: FunctionsService
  ) {
    // this.userLoginDetail = this._jsFunctions.userLogin();
    this.url = environment.BaseChatUrl
    // this.socket = io.connect(this.url, { transports: ['websocket'] });
    // this.socket = io(this.url);

  }


  // answers
  getAnswer(param) {
    this.socket.emit('answerOfChat', param);
  }

  getChatHistory() {
    return Observable.create((observer) => {
      this.socket.on('output', (message) => {
        observer.next(message);
      });
    });
  }

  initiatChat(param) {
    this.socket.emit('initialFromFrontEnd', param);
  }

  sendMsg(param) {
    this.socket.emit('input', param);
  }


  getSupportRequest() {
    return Observable.create((observer) => {
      this.socket.on('newRequestComing', (count) => {
        observer.next(count);
      });
    });
  }

  socketoff(listner) {
    this.socket.removeAllListeners(listner);
  }

  socketon() {
    this.socket.connect();
  }

  socketend() {
    this.socket.disconnect();
  }



}