import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ProtocolService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getUsers(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    validateEmail(email: any) {
        return this.http.post(`${environment.API_URL}user/validate `, email);
    }

    createUser(data: any) {
        return this.http.post(this.url + 'user/reg-admin', data);
    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    deleteUser(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'user/delete', httpOptions);
    }

    exportUser() {
        return this.http.get(this.url + 'user/exportUsers');
    }
    async exportUsers(data) {
        return await this.http.post(this.url + 'user/save-contact',data).toPromise().then(data=>data);
    }
}