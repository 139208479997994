import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable()

export class ChatApiService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    requestChatLists(data: any) {
        return this.http.post(this.url + 'notify/requestList', data);
    }
    myChatLists(id: any) {
        return this.http.post(this.url + 'notify/myChatList', id);
    }
    requestsForZone(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    updateEventRequest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }
}