import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class PlaceService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getPlaces(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createPlace(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updatePlace(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deletePlace(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }
}