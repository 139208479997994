import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class VenueService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getVenues(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createVenue(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateVenue(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteVenue(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importVenue(data: any) {
        return this.http.post(this.url + 'import/venueData', data);
    }
}