import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/components/login/login.component";
import { SubZoneCoordinatesComponent } from "./subzone-coordinates/subzone-coordinates.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { SupportModule } from "./support/support.module";
import { CustomerSupportModule } from "./views/customer-support/customer-support.module";
import { RequestsModule } from "./views/requests/requests.module";
import { ViewsComponent } from "./views/views.component";
import { ZoneCoordinatesComponent } from "./zone-coordinates/zone-coordinates.component";
import { ZoneManagerModule } from "./zone-manager/zone-manager.module";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "zone-coordinates",
    component: ZoneCoordinatesComponent
  },
  {
    path: "subzone-coordinates",
    component: SubZoneCoordinatesComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

    
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "imported-events",
        loadChildren: () =>
          import("./views/imported-events/imported-events.module").then(
            (m) => m.ImportedEventsModule
          ),
      },

      {
        path: "statistics/dashboard",
        loadChildren: () =>
          import("./views/statistics-dashboard/dashboard.module").then((m) => m.StatisticsDashboardModule)
      },


      {
        path: "events",
        loadChildren: () =>
          import("./views/events/events.module").then((m) => m.EventsModule),
      },
      {
        path: "sub-events",
        loadChildren: () =>
          import("./views/sub-events/sub-events.module").then(
            (m) => m.SubEventsModule
          ),
      },
      {
        path: "zones",
        loadChildren: () =>
          import("./views/zones/zones.module").then((m) => m.ZonesModule),
      },
      {
        path: "sub-zones",
        loadChildren: () =>
          import("./views/sub-zones/sub-zones.module").then(
            (m) => m.SubZonesModule
          ),
      },
      {
        path: "venues",
        loadChildren: () =>
          import("./views/venues/venues.module").then((m) => m.VenuesModule),
      },
      {
        path: "interests",
        loadChildren: () =>
          import("./views/interest/interest.module").then(
            (m) => m.InterestModule
          ),
      },
      {
        path: "season",
        loadChildren: () =>
          import("./views/globalseason/globalseason.module").then(
            (m) => m.SeasonModule
          ),
      },
      {
        path: "admin-users",
        loadChildren: () =>
          import("./views/users/users.module").then(
            (m) => m.SeasonModule
          ),
      },
      {
        path: "point-of-interest",
        loadChildren: () =>
          import("./views/point-of-interests/interest.module").then(
            (m) => m.PointOfInterestModule
          ),
      },
      {
        path: "coupon",
        loadChildren: () =>
          import("./views/coupon/coupon.module").then((m) => m.CouponModule),
      },
      {
        path: "places",
        loadChildren: () =>
          import("./views/place/place.module").then((m) => m.PlaceModule),
      },
      {
        path: "merchandise",
        loadChildren: () =>
          import("./views/merchandise/merchandise.module").then(
            (m) => m.MerchandiseModule
          ),
      },
      {
        path: "sponsors",
        loadChildren: () =>
          import("./views/sponsors/sponsors.module").then(
            (m) => m.SponsorsModule
          ),
      },
      {
        path: "home-partners",
        loadChildren: () =>
          import("./views/home-partners/home-partners.module").then(
            (m) => m.HomePartnersModule
          ),
      },
      {
        path: "more-sections",
        loadChildren: () =>
          import("./views/sections/sections.module").then(
            (m) => m.SectionsModule
          ),
      },
      {
        path: "categories",
        loadChildren: () =>
          import("./views/categories/categories.module").then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: "point-categories",
        loadChildren: () =>
          import("./views/point-categories/point-categories.module").then(
            (m) => m.PointCategoriesModule
          ),
      },
      {
        path: "event-sponsors",
        loadChildren: () =>
          import("./views/event-sponsors/event-sponsors.module").then(
            (m) => m.EventSponsorsModule
          ),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./views/notification/notification.module").then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: "protocols",
        loadChildren: () =>
          import("./views/protocol/protocol.module").then(
            (m) => m.ProtocolModule
          ),
      },
      {
        path: "seasons",
        loadChildren: () =>
          import("./views/seasons/seasons.module").then((m) => m.SeasonsModule),
      },
      {
        path: "preference",
        loadChildren: () =>
          import("./views/homescreen-popup/homescreen-popup.module").then(
            (m) => m.HomescreenPopupModule
          ),
      },
      {
        path: "support",
        loadChildren: () =>
          import("./support/support.module").then((m) => m.SupportModule),
      },
      {
        path: "restaurants",
        loadChildren: () =>
          import("./views/restaurants/restaurants.module").then(
            (m) => m.RestaurantsModule
          ),
      },
      {
        path: "pdf",
        loadChildren: () =>
          import("./views/pdf/pdf.module").then((m) => m.PdfModule),
      },


      // {
      //   path: "requests",
      //   loadChildren: () =>
      //     import("./views/requests/requests.module").then(
      //       (m) => m.RequestsModule
      //     ),
      // },
      // {
      //   path: "customers",
      //   loadChildren: () =>
      //     import("./views/customer-support/customer-support.module").then(
      //       (m) => m.CustomerSupportModule
      //     ),
      // },
      // {
      //   path: "zone-managers",
      //   loadChildren: () =>
      //     import("./zone-manager/zone-manager.module").then(
      //       (m) => m.ZoneManagerModule
      //     ),
      // },
      // {
      //   path: "zzone-managers",
      //   loadChildren: () =>
      //     import("./zone-manager/zone-manager.module").then(
      //       (m) => m.ZoneManagerModule
      //     ),
      // },
      {
        path: "banners",
        loadChildren: () =>
          import("./views/banner/banner.module").then((m) => m.BannerModule),
      },
      {
        path: "cuisines",
        loadChildren: () =>
          import("./views/cuisine/cuisine.module").then((m) => m.CuisineModule),
      },
      {
        path: "treasure",
        loadChildren: () =>
          import("./views/treasure-hunt/treasure.module").then(
            (m) => m.TreasureModule
          ),
      },

      // { path: '', redirectTo: 'events', pathMatch: 'full' },
      // { path: '**', redirectTo: 'events', pathMatch: 'full' },
    ],
  },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
