import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable()


export class RestaurantService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    getRestaurantLists(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createRestaurant(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateRestaurant(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteRestaurant(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    getCuisine(data: any) {
        return this.http.post(this.url + 'cuisine/list', data);
    }

}