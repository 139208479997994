import { Component, OnInit,AfterViewInit,DoCheck } from '@angular/core';
// import { AuthService } from "../../auth/services/auth.service";
import { HeaderService } from '../services/header.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  userType: any;
  lang: string;
  logo: string;
  constructor(public headerService: HeaderService) {}

  ngOnInit(): void {

    let currentUser = localStorage.getItem('currentUser');
    this.userType = JSON.parse(currentUser).userType;
    this.lang = localStorage.getItem('lang');
    this.logo = this.headerService.sideBarLogo.getValue();
  }
  ngDoCheck(){
  
    if(window.location.pathname.indexOf('coupon') != -1){
      document.getElementById('couponmenu')?.scrollIntoView();
    }else if(window.location.pathname.indexOf('coordinates') != -1){
      document.getElementById('Coordinates')?.scrollIntoView();
    }else if(window.location.pathname.indexOf('notifications') != -1){
      document.getElementById('notifications')?.scrollIntoView();
    }else if(window.location.pathname.indexOf('event-sponsors')  && window.location.pathname.indexOf('event-sponsors') != -1){
      document.getElementById('Sponsors')?.scrollIntoView();
    }else if(window.location.pathname.indexOf('treasure') != -1){
      document.getElementById('Treasure')?.scrollIntoView();
    }else if(window.location.pathname.indexOf('more-sections') != -1){
      document.getElementById('More-Section')?.scrollIntoView();
    }else if(window.location.pathname.indexOf('home-partners') != -1){
      document.getElementById('home-partners')?.scrollIntoView();
    }
  

  }

  

  
}
