import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable()

export class CallApiService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    requestCallLists(data: any) {
        return this.http.post(this.url + 'user/callrequest', data);
    }
    deleteCallRecord(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    updateCallRecord(data: any) {
        return this.http.patch(this.url + 'category/requestCallUpdate', data);
    }

}