<app-layout></app-layout>
<div class="content">
    <div class="row">
        <div class="col-md-5">
            <div class="user-graph-1">
                <div class="usergraph-head d-flex justify-content-between align-items-center">
                    <h3>Users</h3>
                    <p class="white-bg"></p>
                    <select class="form-select" aria-label="Default select example"
                        (change)="selectMonth($event.target.value)">
                        <option value="1">Last 1 Month</option>
                        <option value="3">Last 3 Month</option>
                        <option value="6" selected>Last 6 Month</option>
                        <option value="9">Last 9 Month</option>
                        <option value="12">Last 12 Month</option>
                    </select>
                </div>
                <div>
                    <canvas id="myChart" width="800" height="400"></canvas>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="total-counts">
                <div class="total-count total-users" routerLink="/users/list">
                    <img src="../../../assets/icons/total-users.svg" alt="">
                    <div>
                        <h2>{{dashboardData.usersCount}}</h2>
                        <p>Total Users</p>
                    </div>
                </div>
                <div class="total-count total-events" routerLink="/events/list">
                    <img src="../../../assets/icons/total-events.svg" alt="">
                    <div>
                        <h2>{{dashboardData.eventCount}}</h2>
                        <p>Total Events</p>
                    </div>
                </div>
                <div class="total-count total-careers" routerLink="/careers/list">
                    <img src="../../../assets/icons/total-careers.svg" alt="">
                    <div>
                        <h2>{{dashboardData.careerCount}}</h2>
                        <p>Total Careers</p>
                    </div>
                </div>
                <div class="total-count total-gs" routerLink="/financial/list">
                    <img src="../../../assets/icons/total-gs.svg" alt="">
                    <div>
                        <h2>{{dashboardData.gsAfasiCount}}</h2>
                        <p>Total GS & FAI</p>
                    </div>
                </div>
                <div class="total-count total-news" routerLink="/news/list">
                    <img src="../../../assets/icons/total-news.svg" alt="">
                    <div>
                        <h2>{{dashboardData.newsCount}}</h2>
                        <p>Total News</p>
                    </div>
                </div>
                <div class="total-count total-resources" routerLink="/resources/list">
                    <img src="../../../assets/icons/total-resources.svg" alt="">
                    <div>
                        <h2>{{dashboardData.resourceCount}}</h2>
                        <p>Total Resources</p>
                    </div>
                </div>
                <div class="total-count total-users" routerLink="/sectors/list">
                    <img src="../../../assets/icons/total-sectors.svg" alt="">
                    <div>
                        <h2>{{dashboardData.sectorCount}}</h2>
                        <p>Total Sectors</p>
                    </div>
                </div>
                <div class="total-count total-partners" routerLink="/partners/list">
                    <img src="../../../assets/icons/total-partners.svg" alt="">
                    <div>
                        <h2>{{dashboardData.partnerCount}}</h2>
                        <p>Total Partners</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>