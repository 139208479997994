<div class="content">
    <div class="">
        <!-- <button class="btn btn-primary">Latest Requests <span>
                <span style="color: red;">0</span>
            </span></button> -->
        <span *ngIf="requestCount" style="border-radius:50%; background-color: #eb2020;border:solid #eb2020 1px;padding:5px;font-size: 20px;height: 40px;
                        width: 39px;
                        display: inline-block;
                        text-align: center;"> <span style="color: white;">{{requestCount}}</span>
        </span>

        <span *ngIf="requestCount" style='margin-left:10px;cursor: pointer;' (click)='refreshChat()'>Refresh Chat</span>
    </div>
    <div class="skill-tabs d-flex justify-content-between align-items-top">
        <div class="skill-tab-left">
            <div class="table-responsive">
                <table class="table mt-2">
                    <thead>
                        <tr>
                            <th scope="col">Serial Number</th>
                            <th scope="col">Participant Name</th>
                            <th scope="col">Participant Message</th>
                            <th scope="col">Event / Zone/Location / Restaurant</th>
                            <th scope="col">Date and Time</th>
                            <th scope="col" style="width: 180px;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let chatRequest of chatRequests; let i = index;">
                            <td>{{chatRequest.serialNumber ? chatRequest.serialNumber : ''}}</td>
                            <td>{{chatRequest.userName ? chatRequest.userName : 'Support'}}</td>
                            <td>{{chatRequest.message}}</td>
                            <td>{{chatRequest.type}} - {{chatRequest.name}}</td>
                            <td>{{chatRequest.createdAt | date:'MMM dd, YYYY hh:mm a'}}</td>
                            <td class="action-btn">
                                <span routerLink="/support/chat/details/" [queryParams]="{id: chatRequest.chatId}">
                                    <img src="../../../../../assets/icons/accept.png" alt=""></span>
                                <!-- <span routerLink="/support/chat/list/">
                                    <img src="../../../../../assets/icons/accept.png" alt=""></span> -->

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination *ngIf="!notFound && totalCount > itemsPerPage" [boundaryLinks]="false" [totalItems]="totalCount"
                [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                previousText="&lsaquo;" nextText="&rsaquo;" [maxSize]="5">
            </pagination>
        </div>
    </div>
</div>