import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class UsersService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getUser(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    createUser(data: any) {
        return this.http.post(this.url + 'user/create-sub', data);
    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    deleteUser(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'user/del', httpOptions);
    }

 }