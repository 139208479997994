import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CuisineService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getCuisine(data: any) {
        return this.http.post(this.url + 'cuisine/list', data);
    }

    createCuisine(data: any) {
        return this.http.post(this.url + 'cuisine/add', data);
    }

    updateCuisine(data: any) {
        return this.http.post(this.url + 'cuisine/update', data);
    }

    getCuisineById(data: any) {
        return this.http.post(this.url + 'cuisine/getCuisineById', data);
    }

    deleteCuisine(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.post(this.url + 'cuisine/delete', data);
    }
}
