<div class="sidebar">
  <!-- <div class="logo" *ngIf="userType == 99"><img routerLink="/events" src="../../../assets/images/riyadh-logo.svg" alt="logo"></div> -->

  <div class="logo" *ngIf="userType == 99"><img routerLink="#" src="../../../assets/images/season-logo-admin.svg" alt="logo"></div>
  <div class="logo" *ngIf="userType == 7"><img routerLink="/events" [src]="headerService.sideBarLogo.getValue()" alt="logo"></div>
  <!-- <a routerLink="/dashboard" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/dashboard.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/dashboard.svg" alt="">
        </span>
        Dashboard</a>  -->
  <div class="header-links">
    <a *ngIf="userType == 7" routerLink="/events" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
      </span>
      Events</a>
    <!-- <a routerLink="/sub-events" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/subevent.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/subevent-svg.svg" alt="">
      </span>
      Sub-Events</a> -->
    <a *ngIf="userType == 7" routerLink="/categories" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/categories.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/categories-svg.svg" alt="">
      </span>Categories</a>
    <a *ngIf="userType == 7" routerLink="/point-categories" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/events.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/events.svg" alt="">
      </span>Point Categories</a>
    <a *ngIf="userType == 7" routerLink="/zones" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/zone.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/zones-svg.svg" alt="">
      </span>
      Zone/Location</a>
    <a *ngIf="userType == 7" routerLink="/sub-zones" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/sub-zones.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/sub-zone-svg.svg" alt="">
      </span>
      Sub-Zone/Sub-Location</a>
    <a *ngIf="userType == 7" routerLink="/venues" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/venue-new.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/venue-svg.svg" alt="">
      </span>
      Venue</a>
      <a *ngIf="userType == 99" routerLink="/season" routerLinkActive="active">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/icons/sidebar/manage-seasons.svg" alt="">
          <img class="white-icon" width="24px" src="../../../assets/icons/sidebar/manage-seasons-white.svg" alt="">
        </span>
        Manage Seasons</a>
        <a *ngIf="userType == 99" routerLink="/admin-users" routerLinkActive="active">
          <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sidebar/sub-admin.svg" alt="">
            <img class="white-icon"  width="24px" src="../../../assets/icons/sidebar/sub-admin-white.svg" alt="">
          </span>
         Sub Admin Users</a>
         
    
    <a *ngIf="userType == 99 || userType == 7" routerLink="/interests" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/area-of-interest.svg" alt="">
        <img class="white-icon" width="24px" src="../../../assets/icons/sidebar/area-of-interest-white.svg" alt="">
      </span>
      Area of Interests</a>
    <a *ngIf="userType == 7" routerLink="/point-of-interest" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/point-of-interst.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/point-of-interst-svg.svg" alt="">
      </span>
      Point of interests</a>
    <!-- <a routerLink="/places" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/nearby.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/nearby-svg.svg" alt="">
      </span>
      Nearby Places</a> -->
    <!-- <a routerLink="/merchandise" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/merchandise.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/merchandise-svg.svg" alt="">
      </span>
      Merchandise</a> -->

    <!-- <a *ngIf="userType == 7" routerLink="/sponsors" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/sponsor.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/sponsores-svg.svg" alt="">
      </span>
      Sponsors</a> -->

    <!-- <a routerLink="/restaurants/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/sponsores.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/sponsores-svg.svg" alt="">
      </span>
      Restaurants</a> -->

    <!-- <a *ngIf="userType == 99" routerLink="/protocols/lists" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/user.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/user-svg.svg" alt="">
      </span>
      Users</a> -->

    <a *ngIf="userType == 7" routerLink="/seasons" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/season-promos.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/season-promos-svg.svg" alt="">
      </span>
      Season Promos</a>

    <a *ngIf="userType == 7" routerLink="/preference" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/homescreen-preferences.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/homescreen-preferences-svg.svg" alt="">
      </span>
      Home screen Preference</a>

    <a *ngIf="userType == 7" routerLink="/home-partners" routerLinkActive="active" id="home-partners">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/homescreen-partner.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/homescreen-partner-svg.svg" alt="">
      </span>
      Home Screen Partners</a>

    <!-- <a routerLink="/requests" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/more.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/more-svg.svg" alt="">
      </span>Requests</a> -->

    <a *ngIf="userType == 7" routerLink="/more-sections" routerLinkActive="active" id="More-Section">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/more-section-content.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/more-section-content-svg.svg" alt="">
      </span>External Section Content</a>
    <a *ngIf="userType == 7" routerLink="/treasure" routerLinkActive="active" id="Treasure">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/treasure-hunt.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/treasure-hunt-svg.svg" alt="">
      </span>Treasure Hunt</a>

      <!-- TODO --------------- STATISTICS DASHBOARD -->

     <a *ngIf="userType == 7" routerLink="/statistics/dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/area-of-interest.svg" alt="">
        <img class="white-icon" width="24px" src="../../../assets/icons/sidebar/area-of-interest-white.svg" alt="">
      </span>
       Questions Statistics Dashboard </a>

    <!-- <a routerLink="/customers" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/more.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/more-svg.svg" alt="">
      </span>Customer Support</a> -->

    <a *ngIf="userType == 7" routerLink="/event-sponsors" routerLinkActive="active" id="Sponsors">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/event-sponsor.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/event-sponsor-svg.svg" alt="">
      </span>Event Sponsors</a>
    <!-- <a *ngIf="userType == 7" routerLink="/notifications" routerLinkActive="active" id="notifications">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/notification.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/notification-svg.svg" alt="">
      </span>Notifications</a> -->
    <!-- <a routerLink="/banners" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/banner.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/banner-svg.svg" alt="">
      </span>Banners</a> -->
    <!-- <a routerLink="/cuisines/list" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/more.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/more-svg.svg" alt="">
      </span>Cuisines</a> -->
    <!-- <a routerLink="/pdf" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/pdf.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/pdf-svg.svg" alt="">
      </span>Pdf</a> -->

    <a *ngIf="userType == 7" routerLink="/coupon" routerLinkActive="active" id="couponmenu">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/faq.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/faq-svg.svg" alt="">
      </span>Coupons</a>
      <a  *ngIf="userType == 7" routerLink="/zone-coordinates" routerLinkActive="active" id="Coordinates">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/icons/sidebar/venue-new.svg" alt="">
          <img class="white-icon" src="../../../assets/icons/sidebar/venue-svg.svg" alt="">
        </span>Zone/Location Coordinates</a>
    <a *ngIf="userType == 7" routerLink="/subzone-coordinates" routerLinkActive="active" id="Coordinates">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/venue-new.svg" alt="">
        <img class="white-icon" src="../../../assets/icons/sidebar/venue-svg.svg" alt="">
      </span>Sub-Zone/Sub-Location Coordinates</a>
    <!-- <a routerLink="">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/icons/sidebar/faq.svg" alt="">
          <img class="white-icon" src="../../../assets/icons/sidebar/faq-svg.svg" alt="">
        </span>Sub-zone Coordinates</a> -->

    <!-- <a routerLink="/reporting" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/sidebar/reporting.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/reporting-svg.svg" alt="">
        </span>Reporting</a> -->
  </div>
</div>