import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class SubZoneService {
  url: any = environment.API_URL;

  constructor(private http: HttpClient) {}

  getZones(data: any) {
    return this.http.post(this.url + "category/list", data);
  }

  createZone(data: any) {
    return this.http.post(this.url + "category/create", data);
  }

  updateZone(data: any) {
    return this.http.patch(this.url + "category/update", data);
  }

  deleteZone(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}),
      body: data,
    };
    return this.http.delete(this.url + "category/delete", httpOptions);
  }

  importZone(data: any) {
    return this.http.post(this.url + "import/zoneData", data);
  }

  getVenues(data: any) {
    return this.http.post(this.url + "category/list", data);
  }

  getInterest(data: any) {
    return this.http.post(this.url + "interest/list", data);
  }
}
