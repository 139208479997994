import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-support',
    template: `
    <div class="container-fluid">
        <app-chat-sidebar></app-chat-sidebar>
        <app-header></app-header>
        <router-outlet></router-outlet>
    </div>
    `
})
export class SupportComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
