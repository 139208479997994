import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "../../auth/services/auth.service";
import { SpinnerVisibilityService } from "ng-http-loader";
import { SharedService } from "../services/shared.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  welcomeUserMsg: boolean = false;
  seasonList: any[] = [];
  defaultSeason: any = null;
  eventsHeading: boolean = false;
  userType: number;

  importedEventsHeading: boolean = false;
  subEventsHeading: boolean = false;
  zoneHeading: boolean = false;
  subZoneHeading: boolean = false;
  venueHeading: boolean = false;
  interestHeading: boolean = false;
  globalSeason: boolean = false;
  usersHeading: boolean = false;
  pointHeading: boolean = false;
  placeHeading: boolean = false;
  merchandiseHeading: boolean = false;
  sponsorHeading: boolean = false;
  sectionHeading: boolean = false;
  categoryHeading: boolean = false;
  pointcategoryHeading: boolean = false;
  homeScreenPartnersHeading: boolean = false;
  eventsponsorsHeading: boolean = false;
  notificationHeading: boolean = false;
  protocolHeading: boolean = false;
  seasonsHeading: boolean = false;
  chatListHeading: boolean = false;
  chatDetailsHeading: boolean = false;
  requestHeading: boolean = false;
  settingHeading: boolean = false;
  restaurantsHeading: boolean = false;
  requestsHeading: boolean = false;
  customerHeading: boolean = false;
  zoneRequestHeading: boolean = false;
  zoneChatHeading: boolean = false;
  zoneCallHeading: boolean = false;
  zoneReportingHeading: boolean = false;
  EventRequestHeading: boolean = false;
  BannerHeading: boolean = false;
  CuisineHeading: boolean = false;
  PopupHeading: boolean = false;
  PdfHeading: boolean = false;
  TreasureHeading: boolean = false;
  CouponHeading: boolean = false;
  ZoneCoordinatesHeading: boolean = false;
  SubZoneCoordinatesHeading: boolean = false;

  default: boolean;
  lang: string;
  user: any;

  private returnUrl: any;

  profilePic: String = "";
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private spinner: SpinnerVisibilityService,
    private sharedService: SharedService
  ) {
    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });
    this._header.importedEventHeading.subscribe((res) => {
      this.importedEventsHeading = res;
    });
    this._header.eventHeading.subscribe((res) => {
      this.eventsHeading = res;
    });
    this._header.subEventHeading.subscribe((res) => {
      this.subEventsHeading = res;
    });
    this._header.ZoneHeading.subscribe((res) => {
      this.zoneHeading = res;
    });
    this._header.SubZoneHeading.subscribe((res) => {
      this.subZoneHeading = res;
    });
    this._header.venueHeading.subscribe((res) => {
      this.venueHeading = res;
    });
    this._header.interestHeading.subscribe((res) => {
      this.interestHeading = res;
    });
    this._header.globalSeason.subscribe((res) => {
      this.globalSeason = res;
    });
    this._header.usersHeading.subscribe((res) => {
      this.usersHeading = res;
    });
    this._header.pointHeading.subscribe((res) => {
      this.pointHeading = res;
    });
    this._header.placeHeading.subscribe((res) => {
      this.placeHeading = res;
    });
    this._header.merchandiseHeading.subscribe((res) => {
      this.merchandiseHeading = res;
    });
    this._header.sponsorHeading.subscribe((res) => {
      this.sponsorHeading = res;
    });
    this._header.sectionHeading.subscribe((res) => {
      this.sectionHeading = res;
    });
    this._header.categoryHeading.subscribe((res) => {
      this.categoryHeading = res;
    });
    this._header.pointcategoryHeading.subscribe((res) => {
      this.pointcategoryHeading = res;
    });
    this._header.eventsponsorsHeading.subscribe((res) => {
      this.eventsponsorsHeading = res;
    });
    this._header.homeScreenPartnersHeading.subscribe((res) => {
      this.homeScreenPartnersHeading = res;
    });
    this._header.notificationHeading.subscribe((res) => {
      this.notificationHeading = res;
    });
    this._header.protocolHeading.subscribe((res) => {
      this.protocolHeading = res;
    });
    this._header.seasonsHeading.subscribe((res) => {
      this.seasonsHeading = res;
    });
    this._header.chatListHeading.subscribe((res) => {
      this.chatListHeading = res;
    });
    this._header.requestHeading.subscribe((res) => {
      this.requestHeading = res;
    });
    this._header.settingHeading.subscribe((res) => {
      this.settingHeading = res;
    });
    this._header.restaurantsHeading.subscribe((res) => {
      this.restaurantsHeading = res;
    });
    this._header.requestsHeading.subscribe((res) => {
      this.requestsHeading = res;
    });
    this._header.customerHeading.subscribe((res) => {
      this.customerHeading = res;
    });
    this._header.zoneRequestHeading.subscribe((res) => {
      this.zoneRequestHeading = res;
    });
    this._header.zoneChatHeading.subscribe((res) => {
      this.zoneChatHeading = res;
    });
    this._header.zoneCallHeading.subscribe((res) => {
      this.zoneCallHeading = res;
    });
    this._header.zoneReportingHeading.subscribe((res) => {
      this.zoneReportingHeading = res;
    });
    this._header.EventRequestHeading.subscribe((res) => {
      this.EventRequestHeading = res;
    });
    this._header.BannerHeading.subscribe((res) => {
      this.BannerHeading = res;
    });
    this._header.CuisineHeading.subscribe((res) => {
      this.CuisineHeading = res;
    });
    this._header.PopupHeading.subscribe((res) => {
      this.PopupHeading = res;
    });
    this._header.PdfHeading.subscribe((res) => {
      this.PdfHeading = res;
    });
    this._header.TreasureHeading.subscribe((res) => {
      this.TreasureHeading = res;
    });
    this._header.CouponHeading.subscribe((res) => {
      this.CouponHeading = res;
    });
    this._header.ZoneCoordinatesHeading.subscribe((res) => {
      this.ZoneCoordinatesHeading = res;
    });
    this._header.SubZoneCoordinatesHeading.subscribe((res) => {
      this.SubZoneCoordinatesHeading = res;
    });

  }

  userName: String;

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    let language = localStorage.getItem("lang");
    this.default = true;
    this.lang = "English";
    this.userName = "Admin";
    this.profilePic = "../../../assets/icons/user-img.svg";
    this.default = language ? (language == "eng" ? true : false) : true;
    this.lang = language ? (language == "eng" ? "English" : "عربي") : "English";
    this.userName = this.user.name ? this.user.name : "";
    this.profilePic = this.user.profileImg
      ? this.user.profileImg
      : "../../../assets/icons/user-img.svg";

    // redirect back to the returnUrl before login
    // this.route.queryParams.subscribe(params => {
    //   
    //   this.returnUrl = params.returnUrl || '/';
    // });
    this.userType = this.user.userType;
    this.setSeason();

  }

  logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("lang");
    localStorage.removeItem("seasonId");
    this.modalRef.hide();
    this.router.navigateByUrl("/auth/login");
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }

  message:any;
  englishSelection() {
    localStorage.setItem('message', JSON.stringify(true));
    // alert('English');
    this.default = true;
    this.lang = "English";
    localStorage.setItem("lang", "eng");
    window.location.reload();
    // 
    // this.router.navigateByUrl(this.returnUrl); // Main page
  }

  arabicSelection() {
    localStorage.setItem('message', JSON.stringify(false));
    // alert('arabic');
    this.default = false;
    this.lang = "عربي";
    localStorage.setItem("lang", "ara");
    window.location.reload();
    // 
    // this.router.navigateByUrl(this.returnUrl); // Main page
  }
  setSeason() {
    // this.spinner.show();
    if (!this.authService.seasonList) {
      let obj = {
        "cId": "",
        "page": "1",
        "pageLimit": "200",
        "search": "",
        "cType": "category",
        "catType": "1"
      }
      this.authService.getSessionList(obj).subscribe((res: any) => {
        if (res.status) {
          this.authService.seasonList = res;
          this.loadDropDown(res);
        }
      });
      // this.spinner.hide();
    } else {
      this.loadDropDown(this.authService.seasonList);
    }
    // this.spinner.hide();
  }
  loadDropDown(res) {
    let seasonIds = this.user.seasson.toString();
    this.seasonList = res.result.filter(e => seasonIds.includes(e._id));
    if (localStorage.getItem('seasonId')) {

      this.defaultSeason = res.result.find(e => e._id == localStorage.getItem('seasonId'));
      if (this.defaultSeason) {
        this._header.sideBarLogo.next(this.defaultSeason['image'] || this.defaultSeason['logoImg'])
      } else {
        this.defaultSeason = this.seasonList[0];
      }
    }else{
      this._header.sideBarLogo.next('../../../assets/images/season-logo-admin.svg')
      localStorage.setItem('seasonId', this.seasonList && this.seasonList.length > 0 ? this.seasonList[0]._id: null);
      this.defaultSeason = this.seasonList[0]
      document.location.reload();

    } 
    // else {
    //   // this.defaultSeason = this.seasonList[0];
    //   localStorage.setItem('seasonId', seasonIds[0]);
    //   

    //   this._header.sideBarLogo.next(this.defaultSeason['image'] || this.defaultSeason['logoImg'])
    // }


  }
  changeSeason(season) {
    if (season?._id != this.defaultSeason?._id) {
      localStorage.setItem('seasonId', season._id);
      this.defaultSeason = season;
      this._header.sideBarLogo.next(this.defaultSeason['image'] || this.defaultSeason['logoImg'])
      document.location.reload();
    } else {
      this.defaultSeason = season;
      this._header.sideBarLogo.next(season.image)
    }
  }
}
