import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Event } from '../models/event.model';

@Injectable()
export class SubEventService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getCat(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    getCategories(data: any) {
        return this.http.post(this.url + 'event/list', data);
    }

    createSubEvent(event: Event) {
        return this.http.post(this.url + 'event/create', event);
    }

    getSubEvents(data: any) {
        return this.http.post(this.url + 'event/admin/list', data);
    }

    updateSubEvent(event: Event) {
        return this.http.patch(this.url + 'event/update', event);
    }

    deleteSubEvent(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        
        return this.http.delete(this.url + 'event/delete', httpOptions);
    }

    getEvents() {
        return this.http.get(this.url + 'event/parent');
    }
}