import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor() {}

  welcomeUserMsg = new BehaviorSubject(false);
  importedEventHeading = new BehaviorSubject(false);
  eventHeading = new BehaviorSubject(false);
  subEventHeading = new BehaviorSubject(false);
  ZoneHeading = new BehaviorSubject(false);
  SubZoneHeading = new BehaviorSubject(false);
  venueHeading = new BehaviorSubject(false);
  interestHeading = new BehaviorSubject(false);
  globalSeason   = new  BehaviorSubject(false);
  usersHeading  = new  BehaviorSubject(false);
  pointHeading = new BehaviorSubject(false);
  placeHeading = new BehaviorSubject(false);
  merchandiseHeading = new BehaviorSubject(false);
  homeScreenPartnersHeading = new BehaviorSubject(false);
  sectionHeading = new BehaviorSubject(false);
  categoryHeading = new BehaviorSubject(false);
  pointcategoryHeading = new BehaviorSubject(false);
  sponsorHeading = new BehaviorSubject(false);
  eventsponsorsHeading = new BehaviorSubject(false);
  notificationHeading = new BehaviorSubject(false);
  protocolHeading = new BehaviorSubject(false);
  seasonsHeading = new BehaviorSubject(false);
  chatListHeading = new BehaviorSubject(false);
  chatDetailsHeading = new BehaviorSubject(false);
  requestHeading = new BehaviorSubject(false);
  settingHeading = new BehaviorSubject(false);
  restaurantsHeading = new BehaviorSubject(false);
  requestsHeading = new BehaviorSubject(false);
  customerHeading = new BehaviorSubject(false);
  zoneRequestHeading = new BehaviorSubject(false);
  zoneChatHeading = new BehaviorSubject(false);
  zoneCallHeading = new BehaviorSubject(false);
  zoneReportingHeading = new BehaviorSubject(false);
  EventRequestHeading = new BehaviorSubject(false);
  BannerHeading = new BehaviorSubject(false);
  CuisineHeading = new BehaviorSubject(false);
  PopupHeading = new BehaviorSubject(false);
  PdfHeading = new BehaviorSubject(false);
  TreasureHeading = new BehaviorSubject(false);
  CouponHeading = new BehaviorSubject(false);
  ZoneCoordinatesHeading = new BehaviorSubject(false);
  SubZoneCoordinatesHeading = new BehaviorSubject(false);
  sideBarLogo = new BehaviorSubject('../../../assets/images/season-logo-admin.svg');
}
