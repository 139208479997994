<div class="sidebar">
    <div class="logo"><img routerLink="/dashboard" src="../../../assets/images/riyadh-logo.svg" alt="logo"></div>

    <a routerLink="/support/requests" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/request.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/request-svg.svg" alt="">
        </span>
        Chat Requests</a>

    <a routerLink="/support/event-requests" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/request.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/request-svg.svg" alt="">
        </span>
        Event Requests</a>

    <a routerLink="/support/chat/list" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/chat.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/chat-svg.svg" alt="">
        </span>
        Chat Lists</a>

    <a routerLink="/zone-managers/call" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/chat.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/chat-svg.svg" alt="">
        </span>
        Call Requests</a>

    <a routerLink="/protocols/lists" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/chat.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/chat-svg.svg" alt="">
        </span>
        Protocol Users</a>
    <a routerLink="/restaurants/list" routerLinkActive="active">
        <span class="sidebar-icon">
            <img class="black-icon" src="../../../assets/icons/chat.svg" alt="">
            <img class="white-icon" src="../../../assets/icons/sidebar/chat-svg.svg" alt="">
        </span>
        Restaurants</a>
</div>