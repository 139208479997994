import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ChatApiService } from '../chat-api.service';
import { ChatService } from '../chat.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  [x: string]: any;

  modalRef: BsModalRef;
  message: string;
  chatRequests: any;
  page: number;
  searchSubmit: any;
  itemsPerPage: any = 9;
  totalCount: any;
  notFound: boolean = false;
  requestCount: number = 0;
  currentPage: number = 4;
  smallnumPages: number = 0;

  constructor(
    private modalService: BsModalService,
    private _header: HeaderService,
    private chatService: ChatService,
    private service: ChatApiService,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    this.requestStatus = 'Test';
    this.currentUser = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(this.currentUser);
    this._header.chatListHeading.next(true);

    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      // this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
      if (this.page) {
        this.currentPage = this.page;
      }
    });

    this.getChatLists(this.page, this.itemsPerPage);

    this.getSupportRequest()
  }
  ngOnDestroy() {
    this._header.chatListHeading.next(false);
  }

  getSupportRequest() {
    let status: any = this.chatService.socketon();
   
    this.chatService.getSupportRequest().subscribe((res: any) => {
      this.requestCount = this.requestCount + res;
    })
  }


  getChatLists(page, limit) {
    this.service.requestChatLists(
      {
        "page": page, "pageLimit": limit
      }
    ).subscribe((res: any) => {
      this.chatRequests = res.result;
      this.totalCount = res.totalCount;

    })
  }

  refreshChat() {
    this.service.requestChatLists(
      {
        "page": 1, "pageLimit": this.itemsPerPage
      }
    ).subscribe((res: any) => {
      this.chatRequests = res.result;
      this.totalCount = res.totalCount;
      this.requestCount = 0;

    })
  }


  pageChanged(event: PageChangedEvent): void {
    this.page = event.page;
    if (!this.searchSubmit) {
      this.getChatLists(event.page, event.itemsPerPage)
    }
    this.searchSubmit = false;

  }
}
