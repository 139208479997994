<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>Change Password</h1>
            <!-- <p>Hello {{userName}}, Welcome Back!</p> -->
        </ng-container>
        <h1 *ngIf="importedEventsHeading">Imported Events</h1>
        <h1 *ngIf="eventsHeading">Events</h1>
        <h1 *ngIf="subEventsHeading">Sub-Events</h1>
        <h1 *ngIf="zoneHeading">Zone/Location</h1>
        <h1 *ngIf="subZoneHeading" style="font-size:23px; max-width: 150px;">Sub-Zone /Sub-Location</h1>
        <h1 *ngIf="venueHeading">Venue</h1>
        <h1 *ngIf="interestHeading">Areas of Interests</h1>
        <h1 *ngIf="globalSeason">Manage Seasons</h1>
        <h1 *ngIf="usersHeading">Sub Admin Users List</h1>
        <h1 *ngIf="pointHeading" style="font-size:25px;">Point of Interests</h1>
        <h1 *ngIf="placeHeading">Nearby Places</h1>
        <h1 *ngIf="merchandiseHeading">Merchandise</h1>
        <h1 *ngIf="homeScreenPartnersHeading">Home Screen Partners</h1>
        <h1 *ngIf="sectionHeading">External Section Content</h1>
        <h1 *ngIf="categoryHeading">Categories</h1>
        <h1 *ngIf="pointcategoryHeading">Point Categories</h1>
        <h1 *ngIf="sponsorHeading">Sponsors</h1>
        <h1 *ngIf="eventsponsorsHeading">Event Sponsors</h1>
        <h1 *ngIf="notificationHeading">Notifications</h1>
        <h1 *ngIf="protocolHeading"> Users</h1>
        <h1 *ngIf="seasonsHeading">Season Promos</h1>
        <h1 *ngIf="chatListHeading">Chat Lists</h1>
        <h1 *ngIf="chatDetailsHeading">Chat Details</h1>
        <h1 *ngIf="requestHeading">Request</h1>
        <h1 *ngIf="settingHeading">Settings</h1>
        <h1 *ngIf="restaurantsHeading">Restaurants</h1>
        <h1 *ngIf="requestsHeading">Requests</h1>
        <h1 *ngIf="customerHeading">Customer Support</h1>
        <h1 *ngIf="zoneRequestHeading">Requests</h1>
        <h1 *ngIf="zoneChatHeading">Chat Details</h1>
        <h1 *ngIf="zoneCallHeading">Call Details</h1>
        <h1 *ngIf="zoneReportingHeading">Reportings</h1>
        <h1 *ngIf="EventRequestHeading">Event Requests</h1>
        <h1 *ngIf="BannerHeading">Banners</h1>
        <h1 *ngIf="CuisineHeading">Cuisine</h1>
        <h1 *ngIf="PopupHeading">Home Screen Preferences</h1>
        <h1 *ngIf="PdfHeading">Pdf</h1>
        <h1 *ngIf="TreasureHeading">Treasure Hunt</h1>
        <h1 *ngIf="CouponHeading">Coupons</h1>
        <h1 *ngIf="ZoneCoordinatesHeading">Zone/Location Coordinates</h1>
        <h1 *ngIf="SubZoneCoordinatesHeading">Sub-Zone/Sub-Location Coordinates</h1>
    </div>


    <div class="right-sec">
        <div class="btn-group lang" dropdown placement="bottom right" *ngIf="userType == 7">
            <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle"
                style="min-width: 150px; margin-right: 15px;" aria-controls="dropdown-alignment">
                {{defaultSeason?.title}} <img class="drop-down-arrow" src="../../../assets/icons/drop-down-arrow.svg" alt="">
            </button>
            <ul id="dropdown-alignment" style="margin-right: 15px;" *dropdownMenu
                class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment" >
                <li role="menuitem" *ngFor="let season of seasonList"><a class="dropdown-item " [ngClass]="defaultSeason?._id == season?._id ? 'language' : ''"
                        aria-selected="defaultSeason?._id == season?._id ? true : false"  (click)="changeSeason(season)">
                        {{season.title}}
                    </a>
                </li>
         
            </ul>
        </div>

        <div class="btn-group lang" dropdown placement="bottom right">
            <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle"
                style="min-width: 130px; margin-right: 15px;" aria-controls="dropdown-alignment">
                <!-- <img class="user-lang" src="../../../assets/icons/english-flag.svg" alt=""> -->
                {{lang}} <img class="drop-down-arrow" src="../../../assets/icons/drop-down-arrow.svg" alt="">
            </button>
            <ul id="dropdown-alignment" style="margin-right: 15px;" *dropdownMenu
                class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
                <li role="menuitem"><a class="dropdown-item " [ngClass]="default ? 'language' : ''"
                        aria-selected="default ? true : false" (click)="englishSelection()">
                        <!-- <img class="user" src="../../../assets/icons/english-flag.svg" alt=""> -->
                        English
                    </a></li>
                <li role="menuitem"><a class="dropdown-item" (click)="arabicSelection()" aria-selected="true"
                        [ngClass]="!default ? 'language' : ''">
                        <!-- <img class="user" src="../../../assets/icons/english-flag.svg" alt=""> -->
                        عربي
                    </a></li>
            </ul>
        </div>

        <div class="btn-group" dropdown placement="bottom right">
            <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle"
                aria-controls="dropdown-alignment">
                <!-- <img class="user" [src]="profilePic" alt=""> -->
                {{userName}}
                <img class="drop-down-arrow" src="../../../assets/icons/drop-down-arrow.svg" alt="">
            </button>
            <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-alignment">
                <li role="menuitem"><a class="dropdown-item" style="background: #346ef6; color: #fff;"
                        routerLink="/edit-profile">Change Password</a></li>
                <li style="cursor: pointer;" role="menuitem"><a class="dropdown-item"
                        (click)="openModal(template)">Logout</a></li>
            </ul>
        </div>

    </div>
</div>

<!-- Logout fonfirm -->
<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>
</ng-template>