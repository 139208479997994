import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { User } from '../models/user.model';

@Injectable()

export class AuthService {

    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    public seasonList:any=null;
    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

     }

    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return JSON.parse(localStorage.getItem('currentUser'));
        return false;
    }

    public get language() {
        let lang = localStorage.getItem('lang');
        if (lang) {
            return lang;
        }
        return false;
    }

    login(user: User) {        
        return this.http.post<any>(this.url + 'user/admin/login', user);
    }

    reset(email: any) {
        return this.http.patch<any>(this.url + 'user/reset-password', email);
    }

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('lang');
        localStorage.removeItem('seasonId');
        localStorage.removeItem('seasonIds');
        localStorage.removeItem('seasonLogo');
        
        // return this.http.patch(this.url + 'user/logout', id);
    }

    updateuser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    changePassword(data: any) {
        return this.http.patch(this.url + 'user/change-password', data);
    }

    getSessionList(obj) {
        // let obj = {
        //     "cId": "",
        //     "page": "1",
        //     "pageLimit": "200",
        //     "search": "",
        //     "cType": "category",
        //     "catType": "1"
        // }
        return this.http.post(this.url + 'seasson/list', obj);
    }

} 