

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class NotificationService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notify/admin/list', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notify/send', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notify/delete', httpOptions);
    }

    getEvents(data: any) {
        return this.http.post(this.url + 'event/admin/list', data);
    }



}