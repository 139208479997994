import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class BannerService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }


    getBanner(data: any) {
        return this.http.post(this.url + 'banner/list', data);
    }

    createBanner(data: any) {
        return this.http.post(this.url + 'banner/add', data);
    }

    updateBanner(data: any) {
        return this.http.post(this.url + 'banner/update', data);
    }

    getBannerById(id: any) {
        return this.http.post(this.url + 'banner/getBannerById', id);
    }

    // deleteZone(data: any) {
    //     const httpOptions = {
    //         headers: new HttpHeaders({}), body: data
    //     };
    //     return this.http.delete(this.url + 'category/delete', httpOptions);
    // }

    // importZone(data: any) {
    //     return this.http.post(this.url + 'import/zoneData', data);
    // }

    // getVenues(data: any) {
    //     return this.http.post(this.url + 'category/list', data);
    // }
}