import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class SeasonService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getSeasons(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createSeason(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateSeason(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteSeason(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    getEvents(data: any) {
        return this.http.post(this.url + 'event/promo/list',data);
    }
}