<div id="auth-width">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5">
        <div class="login-banner">
          <!-- <img src="../../../../assets/images/login-banner.svg" alt="login-banner"> -->
          <img src="../../../../assets/images/auth/login-banner.svg" alt="login-banner">
          <!-- <img [src]="logo" alt="login-banner"> -->
        </div>
      </div>
      <div class="col-md-7">
        <div class="login-field">
          <div class="login-box">
            <h3>Sign In</h3>
            <!-- <p class="mb/ss-3">Promoting skilled trades and technologies in Ontario since 1989.</p> -->
            <form [formGroup]="loginForm" (submit)="login()">
              <div class="form-group">
                <label for="email">Email Address *</label>
                <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt=""></span>
                <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                  <span *ngIf="f.email.errors.required">
                    Email is required.
                  </span>
                  <span *ngIf="f.email.errors.email">
                    Invalid email.
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="email">Password *</label>
                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt=""></span>
                <input type="password" class="form-control" placeholder="Password" id="password"
                  formControlName="password">
                <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                  <span *ngIf="f.password.errors.required">
                    Password is required.
                  </span>
                  <span *ngIf="f.password.errors.minlength">
                    Password must contain at least 6 characters.
                  </span>
                </div>
                <div class="forgot-pass text-right mt-2" routerLink="/auth/forgot-password">Forgot Password?</div>
              </div>
              <!-- <div class="form-group users-type">
                <p>Please Select User Type : </p>
                <div class="row">
                  <div class="col-md-4">
                    <div class="select-user" [class.selected]="check===1">
                      <img src="../../../../assets/images/admin.svg" alt=""><br>
                      <input type="radio" id="html" name="fav_language" value="1" checked="checked"
                        (change)="onAdminSelection($event)">
                        <label for="admin">Admin</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="select-user" [class.selected]="check===2">
                      <img src="../../../../assets/images/customer.svg" alt=""><br>
                      <input type="radio" id="css" name="fav_language" value="5" (change)="onSupportSelection($event)">
                        <label for="support">Customer Support</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="select-user" [class.selected]="check===3">
                      <img src="../../../../assets/images/customer.svg" alt=""><br>
                      <input type="radio" id="zone" name="fav_language" value="6" (change)="onZoneSelection($event)">
                        <label for="support">Zone Manager</label>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="customBtn">
              <div class="row no-gutters">
                <div class="col-lg-6 col-md-6">
                  <button *ngIf="userType == 99" type="button" class="btn btn-primary active" (click)="assignUser(1)" ><img src="../../../../assets/images/auth/Admin.svg" style="width: 34px ;padding-right: 3px">Administrator</button>
                  <button *ngIf="userType != 99" type="button" class="btn btn-primary" (click)="assignUser(1)" ><img src="../../../../assets/images/auth/Admin.svg" style="width: 34px;padding-right: 3px">Administrator</button>
                </div>
                <div class="col-lg-6 col-md-6">
                  <button *ngIf="userType != 7" type="button" class="btn btn-primary" (click)="assignUser(7)"><img src="../../../../assets/images/auth/Sub-admin.svg" style="width: 34px;padding-right: 3px">Sub Admin</button>
                  <button *ngIf="userType == 7" type="button" class="btn btn-primary active" (click)="assignUser(7)" ><img src="../../../../assets/images/auth/Sub-admin.svg" style="width: 34px;padding-right: 3px">Sub Admin</button>
                </div>
              </div>
            </div>

              <button type="submit" class="btn btn-primary">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>