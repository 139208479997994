import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  resetForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email])
  })

  ngOnInit(): void {
  }

  get f() {
    return this.resetForm.controls;
  }

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;
    this.authService.reset(this.resetForm.value)
      .subscribe((res: any) => {
        if (res.status == true) {
          this.router.navigateByUrl('/auth/email-send');
        }
      })
  }

}
