import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class PointOfInterestService {
  url: any = environment.API_URL;

  constructor(private http: HttpClient) {}

  createPointOfInterest(data: any) {
    return this.http.post(this.url + "interest/add", data);
  }

  getPointOfInterest(data: any) {
    return this.http.post(this.url + "interest/list", data);
  }

  updatePointOfInterest(data: any) {
    return this.http.post(this.url + "interest/update", data);
  }

  deletePointOfInterest(data: any) {
    return this.http.post(this.url + "interest/delete", data);
  }
}
