import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class TreasureService {
  url: any = environment.API_URL;
  env: any = environment.isDev;
  admin_base_url: string= this.env? 
  'https://apidev.saudievents.sa/admin':
  'https://api.saudievents.sa/admin'
  constructor(private http: HttpClient) {}

  createTreasure(data: any) {
    return this.http.post(this.url + "treasure/add", data);
  }

  getTreasures(data: any) {
    return this.http.post(this.url + "treasure/list", data);
  }

  updateTreasure(data: any) {
    return this.http.post(this.url + "treasure/update", data);
  }

  deleteTreasure(data: any) {
    return this.http.post(this.url + "treasure/delete", data);
  }

  getQrCode(data: any) {
    return this.http.post(this.url + "import/getQRCode", data);
  }


  getQuestionBytreasureId (id) {
    return this.http.get(this.admin_base_url + `/manage/question/treasure/${id}`);
  }
  addQuestion(data: any) {
    return this.http.post(this.admin_base_url + "/manage/question", data);
  }

  updateQuestion(id , data: any) {
    return this.http.patch(this.admin_base_url + `/manage/question/${id}`, data);
  }

  deleteQuestion(id) {
    return this.http.delete(this.admin_base_url + `/manage/question/${id}`);
  }


}
