import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Riyadh-Seasons-admin';
  status = 'ONLINE';
  isConnected = true;
  constructor(
    private connectionService: ConnectionService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
        this.authService.logout();
        this.toastrService.error('Connection Lost');
      }
    })
  }
}
