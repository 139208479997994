import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable()
export class SharedService {
  url: any = environment.API_URL;
  constructor(private http: HttpClient) {}

  private siblingMsg = new Subject<any>();

  uploadImage(file: any) {
    return this.http.post(this.url + "upload/image", file);
  }

  uploadVideo(file: any) {
    return this.http.post(this.url + "upload/video", file);
  }

  uploadPdf(file: any) {
    return this.http.post(this.url + "upload/pdf", file);
  }

  private subject = new Subject();
 
    sendMessage(message: string) {
        this.subject.next(message);
    }
 
    clearMessages() {
        this.subject.next();
    }
 
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
