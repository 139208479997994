import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { ImageCropperModule } from "ngx-image-cropper";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgHttpLoaderModule } from "ng-http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { ModalModule } from "ngx-bootstrap/modal";
import { CustomFormsModule } from "ng2-validation";

import { NgxSliderModule } from "@angular-slider/ngx-slider";

import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./auth/auth.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { AuthService } from "./auth/services/auth.service";
import { SharedService } from "./shared/services/shared.service";
import { EventService } from "./views/events/services/event.service";
import { DashboardService } from "./dashboard/services/dashboard.service";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { SessionService } from "./shared/services/session.service";
import { SubEventService } from "./views/sub-events/services/sub-event.service";
import { InterestService } from "./views/interest/interest.service";
import { GlobalseasonService } from "./views/globalseason/globalseason.service"
import { UsersService } from "./views/users/users.service";
import { ZoneService } from "./views/zones/services/zone.service";
import { VenueService } from "./views/venues/venue.service";
import { MerchandiseService } from "./views/merchandise/merchandise.service";
import { PlaceService } from "./views/place/place.service";
import { SponsorService } from "./views/sponsors/sponsor.service";
import { CategoriesService } from "./views/categories/categories.service";
import { EventSponsorService } from "./views/event-sponsors/event-sponsor.service";
import { ImportedEventService } from "./views/imported-events/services/imported-event.service";
import { NotificationService } from "./views/notification/notification.service";
import { ProtocolService } from "./views/protocol/services/protocol.service";
import { SeasonService } from "./views/seasons/season.service";
import { ChatService } from "./support/chat.service";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { RestaurantService } from "./views/restaurants/restaurant.service";
import { RequestService } from "./views/requests/request.service";
import { CustomerService } from "./views/customer-support/services/customer.service";
import { ChatApiService } from "./support/chat-api.service";
import { CallApiService } from "./support/call-api.service";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { SupportModule } from "./support/support.module";
import { BannerService } from "./views/banner/services/banner.service";
import { CuisineService } from "./views/cuisine/cuisine.service";
import { PopupService } from "./views/homescreen-popup/homescreen-popup.service";
import { SubZoneService } from "./views/sub-zones/services/sub-zone.service";
import { PdfService } from "./views/pdf/pdf.service";
import { TreasureService } from "./views/treasure-hunt/services/treasure.service";
import { PointOfInterestService } from "./views/point-of-interests/services/interest.service";
import { PointCategoriesService } from "./views/point-categories/point-categories.service";
import { CouponService } from "./views/coupon/services/coupon.service";
import { SubZoneCoordinatesComponent } from "./subzone-coordinates/subzone-coordinates.component";
import { MatSelectModule } from "@angular/material/select";
import { ZoneCoordinatesComponent } from "./zone-coordinates/zone-coordinates.component";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const config: SocketIoConfig = { url: environment.BaseChatUrl, options: {} };
//import { EventSponsorsComponent } from './views/event-sponsors/event-sponsors.component';
// import { ZoneService } from './views/zones/services/zone.service';
// ngx-bootstrap

@NgModule({
  declarations: [
    AppComponent,
    ViewsComponent,
    EditProfileComponent,
    SubZoneCoordinatesComponent,
    ZoneCoordinatesComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    SupportModule,
    ImageCropperModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    //HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ModalModule.forRoot(),
    CustomFormsModule,
    NgxSliderModule,
    //SocketIoModule.forRoot(config),
    NgxIntlTelInputModule,
    MatSelectModule,
    InfiniteScrollModule,
    
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
    EventService,
    DashboardService,
    SessionService,
    SubEventService,
    InterestService,
    GlobalseasonService,
    UsersService,
    PointOfInterestService,
    ZoneService,
    SubZoneService,
    VenueService,
    MerchandiseService,
    PlaceService,
    SponsorService,
    CategoriesService,
    EventSponsorService,
    ImportedEventService,
    NotificationService,
    ProtocolService,
    SeasonService,
    ChatService,
    RestaurantService,
    RequestService,
    CustomerService,
    ChatApiService,
    CallApiService,
    BannerService,
    CuisineService,
    PopupService,
    PdfService,
    TreasureService,
    PointCategoriesService,
    CouponService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
