import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) {

  }

  getCategories(data: any) {
    return this.http.post(this.url + 'category/list', data);
  }

  createCategory(data: any) {
    return this.http.post(this.url + 'category/create', data);
  }

  updateCategory(data: any) {
    return this.http.patch(this.url + 'category/update', data);
  }

  deleteCategory(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}), body: data
    };
    return this.http.delete(this.url + 'category/delete', httpOptions);
  }

  importCategory(data: any) {
    return this.http.post(this.url + 'import/categoryData', data);
  }
}
