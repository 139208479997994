import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ZoneService } from "../views/zones/services/zone.service";
// declare const google: any;
import * as $ from "jquery";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HeaderService } from "../shared/services/header.service";

@Component({
  selector: "app-subzone-coordinates",
  templateUrl: "./subzone-coordinates.component.html",
  styleUrls: ["./subzone-coordinates.component.scss"],
})
export class SubZoneCoordinatesComponent implements OnInit, AfterViewInit {
  seasonId:string = localStorage.getItem('seasonId');
  constructor(
    private zoneService: ZoneService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private header: HeaderService
  ) { }

  labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  labelIndex = 0;

  map: google.maps.Map;
  markers = [];
  uniqueId = 0;
  coord: google.maps.LatLngLiteral;
  infoWindow = new google.maps.InfoWindow({});
  latLng: any = { lat: 21.506447, lng: 39.184464 };
  LatLngBounds = google.maps.LatLngBounds;

  coordForm: UntypedFormGroup;

  triangleCoords: google.maps.LatLngLiteral[] = [
    // { lat: 21.506447, lng: 39.184464 },
    // { lat: 21.50496, lng: 39.186513 },
    // { lat: 21.50275, lng: 39.184799 },
    // { lat: 21.50315, lng: 39.182218 },
    // { lat: 21.505733, lng: 39.180433 },
    // { lat: 21.508501, lng: 39.181775 },
  ];

  bermudaTriangle = new google.maps.Polygon({
    // paths: this.triangleCoords,
    strokeColor: "#5bbbeb",
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: "#5bbbeb",
    fillOpacity: 0.35,
    draggable: true,
    editable: true,
    geodesic: true,
  });
  initMap(): void {
    let tri = this;
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 13,
        center: this.latLng,
        mapTypeId: "terrain",
      }
    );




    this.bermudaTriangle.setPaths(this.triangleCoords);
    // this.bermudaTriangle.addListener("click", this.showArrays);
    this.bermudaTriangle.addListener("click", function (event) {
      tri.openInfoWindow(event.latLng);
    });

    // google.maps.event.addListener(outerPath, 'set_at', function() {
    //   
    // });

    this.bermudaTriangle.addListener('dragend', function (event) {
      //do drag end stuff here
      const polygon = this as google.maps.Polygon;
      const vertices = polygon.getPath();
      for (let i = 0; i < vertices.getLength(); i++) {
        // const xy:any = vertices.getAt(i).lat();
      }
    });

    // this.bermudaTriangle.addListener('drag', function (event) {
    //   // tri.showArrays();
    //   
    // })




    // This event listener calls addMarker() when the map is clicked.
    google.maps.event.addListener(this.map, "click", (event) => {
      this.addMarker(event.latLng, this.map, event.latLng.toJSON());
    });
  }
  lang: string = 'eng';
  ngOnInit(): void {
    // document.getElementById('map').remove();
    // this.bermudaTriangle.setMap(null);
    this.lang = localStorage.getItem('lang');
    this.getsubZones();
    this.initForm();
    this.header.SubZoneCoordinatesHeading.next(true);
  }

  ngAfterViewInit() {
    this.initMap();
  }

  ngOnDestroy(): void {
    this.header.SubZoneCoordinatesHeading.next(false);
    document.getElementById('map').remove()
    this.bermudaTriangle.setMap(null);
  }

  initForm() {
    this.coordForm = this.fb.group({
      subzone: new UntypedFormControl("", [Validators.required]),
      color: new UntypedFormControl("", [Validators.required]),
    });
  }

  get f() {
    return this.coordForm.controls;
  }

  openInfoWindow(e: any) {
    let latlng = e.toJSON();
    let myData;
    // for(let i = 0; i < this.triangleCoords.length; i++){
    //   if(this.triangleCoords[i].lat  == latlng.lat && this.triangleCoords[i].lng == latlng.lng){
    //     myData = i;
    //   }
    // }
    var deleteButton =
      "<p> latitude : " +
      latlng.lat +
      "</p><p> longitude : " +
      latlng.lng +
      "</p>" +
      '</br><button id="deleteButton" >Delete</button>';
    let data = this;
    this.infoWindow.setContent(deleteButton);
    this.infoWindow.setPosition(latlng);
    this.infoWindow.open(data.map)

    google.maps.event.addListener(data.infoWindow, "domready", function () {
      $("#deleteButton").click(function () {
        data.deleteVertices(latlng);
      });
    });
  }

  deleteVertices(latlng: any) {
    for (let i = 0; i < this.triangleCoords.length; i++) {
      if (this.triangleCoords[i].lat == latlng.lat && this.triangleCoords[i].lng == latlng.lng) {
        this.triangleCoords.splice(i, 1);
        this.bermudaTriangle.setPaths(this.triangleCoords);
        this.bermudaTriangle.setMap(this.map)
      }
    }
    // this.bermudaTriangle.setMap(this.map);
    this.infoWindow.close();
  }

  // Adds a marker to the map.
  addMarker(location: google.maps.LatLngLiteral, map: google.maps.Map, latlng) {
    // Construct the polygon.

    if (this.triangleCoords.includes(latlng)) {
      return;
    } else {
      this.triangleCoords.push(latlng);
      this.bermudaTriangle.setPaths(this.triangleCoords);
      this.bermudaTriangle.setMap(this.map);

      // let newMarker: any = new google.maps.Marker({
      //   position: location,
      //   map: map,
      // });
      // //Set unique id
      // newMarker.id = this.uniqueId;
      // //Add marker to the array.
      // this.markers.push(newMarker);
      // 

      // this.uniqueId++;

      // let data: any = this;
      // var deleteButton =
      //   "<p> latitude : " +
      //   latlng.lat +
      //   "</p><p> longitude : " +
      //   latlng.lng +
      //   "</p>" +
      //   '</br><button id="deleteButton" data-id="' +
      //   this.uniqueId +
      //   '">Delete</button>';

      // google.maps.event.addListener(newMarker, "click", function (mouseEvent) {
      //   data.infoWindow.setContent(deleteButton);
      //   data.infoWindow.open(map, newMarker);

      //   data.coord = mouseEvent.latLng.toJSON();
      // });
      // delete
      // google.maps.event.addListener(data.infoWindow, "domready", function () {
      //   $("#deleteButton").click(function () {
      //     data.deleteMarker($(this).data("id"));
      //   });
      // });
    }
  }

  // showArrays() {
  //   // Since this polygon has only one path, we can call getPath() to return the
  //   // MVCArray of LatLngs.
  //   // @ts-ignore
  //   const polygon = this as google.maps.Polygon;
  //   const vertices = polygon.getPath();
  //   let coordinates: any = [];
  //   

  //   // Iterate over the vertices.
  //   for (let i = 0; i < vertices.getLength(); i++) {
  //     const xy = vertices.getAt(i);
  //     coordinates.push({
  //       lat: xy.lat(),
  //       lng: xy.lng()
  //     })

  //   }
  //   
  //   return coordinates;
  // }

  deleteMarker(markerId) {
    for (var i = 0; i < this.markers.length; i++) {
      if (this.markers[i].id === markerId - 1) {
        // let index = this.triangleCoords.indexOf(this.coord);
        this.triangleCoords.splice(i, 1);
        this.bermudaTriangle.setPaths(this.triangleCoords);
        this.bermudaTriangle.setMap(this.map);
        this.markers[i].setMap(null);
        this.markers.splice(i, 1);
        // this.uniqueId--;
      }
    }
  }

  // address: any;
  // async displayLocation(latitude, longitude) {
  //   var geocoder;
  //   geocoder = new google.maps.Geocoder();
  //   var latlng = new google.maps.LatLng(latitude, longitude);

  //   let data = this;
  //   geocoder.geocode({ latLng: latlng }, function (results, status) {
  //     if (status == google.maps.GeocoderStatus.OK) {
  //       if (results[0]) {
  //         
  //         var add = results[0].formatted_address;
  //         // var value = add.split(",");
  //         // this.address = add;
  //         
  //       } else {
  //       }
  //     } else {
  //     }
  //   });
  // }

  subZones: any;
  getsubZones() {
    this.zoneService
      .getZones({
        cId: "",
        page: 1,
        pageLimit: 100,
        search: "",
        cType: "subZone",
        seasonId: this.seasonId
      })
      .subscribe((res: any) => {
        this.subZones = res.result;
      });
  }

  submitted: boolean = false;
  updateSubZone() {
    this.submitted = true;


    // 

    this.triangleCoords = [];
    let vertices = this.bermudaTriangle.getPaths();
    let coord;
    for (let i = 0; i < vertices.getLength(); i++) {
      coord = vertices.getAt(i);
    }

    for (let j = 0; j < coord.getLength(); j++) {
      this.triangleCoords.push({ lat: coord.getAt(j).lat(), lng: coord.getAt(j).lng() })
      const xy: any = coord.getAt(j).lat();
    }
    // }

    if (this.coordForm.invalid || this.triangleCoords.length < 3) {
      if (this.triangleCoords.length < 3) {
        this.toastr.error("Please select atleast 3 coordinates");
      }
      return;
    }
    let data = {
      cType: "subZone",
      cId: this.coordForm.value.subzone._id,
      coordinates: this.triangleCoords,
      colorCode: this.coordForm.value.color,
      seasonId: this.seasonId
    };
    this.submitted = false;
    this.zoneService.updateZone(data).subscribe(
      (res: any) => {
        if (res.status == true) {
          this.coordForm.reset();
          this.getsubZones();
          this.initMap();
          this.triangleCoords = [];
          this.markers = [];
        } else {
          this.toastr.error(res.message);
        }
      },
      (err) => {
        this.toastr.error("Unable to update subZone");
      }
    );
  }

  onSelect(e: any) {
    let markers: google.maps.Marker[] = [];
    let marker = new google.maps.Marker();
    let lat: any = "";
    let long: any = "";
    this.triangleCoords = [];
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers.length = 0;
    this.markers = [];

    if (e.coordinates) {
      if (e.coordinates.length > 0) {
        lat = Number(e.coordinates[0].lat);
        long = Number(e.coordinates[0].lng);
      } else if (e.lat && e.long) {
        lat = Number(e.lat);
        long = Number(e.long);
      }
    } else if (e.lat && e.long) {
      lat = Number(e.lat);
      long = Number(e.long);
    }

    this.map.setCenter(new google.maps.LatLng(e.lat, e.long));
    marker.setPosition({ lat: Number(e.lat), lng: Number(e.long) });
    marker.setMap(this.map);
    marker.setAnimation(google.maps.Animation.DROP);
    this.markers.push(marker);
    
    this.map.setZoom(18);
    if (e.colorCode) this.coordForm.get("color").patchValue(e.colorCode);
    if(e.coordinates){
      if (e.coordinates.length >= 3) {
        e.coordinates.forEach((el: any) => {
          let latlng: any = { lat: Number(el.lat), lng: Number(el.lng) };
          this.addMarker(latlng, this.map, latlng);
        });
        this.bermudaTriangle.setOptions({ strokeColor: e.colorCode, fillColor: e.colorCode });
  
        // this.triangleCoords = e.coordinates;
        // this.bermudaTriangle.setPaths(this.triangleCoords);
        // this.bermudaTriangle.setMap(this.map);
        // this.markers =
      }
    }
  }
}
