import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  url: any = environment.API_URL;


  constructor(
    private http: HttpClient
  ) {

  }

  getSponsor(data: any) {
    return this.http.post(this.url + 'category/list', data);
  }

  createSponsor(data: any) {
    return this.http.post(this.url + 'category/create', data);
  }

  updateSponsor(data: any) {
    return this.http.patch(this.url + 'category/update', data);
  }

  deleteSponsor(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}), body: data
    };
    return this.http.delete(this.url + 'category/delete', httpOptions);
  }
}
