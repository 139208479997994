import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestComponent } from './request/request.component';
import { RouterModule, Routes } from '@angular/router';
import { ChatSidebarComponent } from './chat-sidebar/chat-sidebar.component';
import { SupportComponent } from './support.component';
import { SharedModule } from '../shared/shared.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { ChatApiService } from './chat-api.service';
import { FormsModule } from '@angular/forms';
import { RestaurantsModule } from '../views/restaurants/restaurants.module';
import { EventRequestComponent } from './event-request/event-request.component';
import { ZoneManagerModule } from '../zone-manager/zone-manager.module';
const config: SocketIoConfig = { url: environment.BaseChatUrl, options: { transports: ['websocket'] } };



const routes: Routes = [
  {
    path: '',
    component: SupportComponent,
    children: [
      {
        path: 'chat',
        loadChildren: () =>
          import('./chat/chat.module').then((m) => m.ChatModule)
      },
      {
        path: 'requests',
        component: RequestComponent
      },
      {
        path: 'event-requests',
        component: EventRequestComponent
      },
      {
        path: 'call-detail',
        component: EventRequestComponent
      }
    ]
  }
]


@NgModule({
  declarations: [
    SupportComponent,
    RequestComponent,
    ChatSidebarComponent,
    EventRequestComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PaginationModule.forRoot(),
    RouterModule.forChild(routes),
   // SocketIoModule.forRoot(config),
    FormsModule,
    // ZoneManagerModule
    // RestaurantsModule
  ],
  exports: [
    SupportComponent,
    RequestComponent,
    ChatSidebarComponent,
    EventRequestComponent
  ]
})
export class SupportModule { }
