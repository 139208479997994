import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class CouponService {
  url: any = environment.API_URL;

  constructor(private http: HttpClient) {}

  createPointOfInterest(data: any) {
    return this.http.post(this.url + "interest/add", data);
  }

  getPointOfInterest(data: any) {
    return this.http.post(this.url + "interest/list", data);
  }

  updatePointOfInterest(data: any) {
    return this.http.patch(this.url + "interest/update", data);
  }

  deletePointOfInterest(data: any) {
    return this.http.post(this.url + "interest/delete", data);
  }

  createCoupon(data: any) {
    return this.http.post(this.url + "coupon/create", data);
  }

  // getCoupons(data: any) {
  //   return this.http.post(this.url + "coupon/list", data);
  // }

  getCoupons(data: any) {   
    // return this.http.patch(this.url + "coupon/update", data);
      return this.http.post(this.url + "coupon/aggregatedlist", data);
  }

  updateCoupons(data: any) {
  //  return this.http.patch(this.url + "coupon/update", data);
   return this.http.patch(this.url + "coupon/updateCoupon", data);
  }

  deleteCoupon(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}), body: data
  };
    return this.http.delete(this.url + "coupon/delete", httpOptions);
  }
  importCsv(data: any) {
    return this.http.post(this.url + "coupon/importCsv", data);
  }

  deleteCoupanByTitle(data:any){
      const httpOptions = {
        headers: new HttpHeaders({}), body: data
    };
    console.log('data', data);
    return this.http.delete(this.url + 'coupon/deletecoupon', httpOptions);
  }


  coupanEditNew(data:any){
    return this.http.post(this.url + 'coupon/edit', data);
  }

  getCouponCodes(data:any){
    return this.http.post(this.url + 'coupon/listCouponCodes', data);
  }

  getCouponDetails(data:any){
    return this.http.post(this.url + 'coupon/listCouponData', data);
  }

  uploadCSVCreate(file: any) {
    return this.http.post(this.url + "coupon/importCsv", file);
  }

  uploadCSVUpdate(file: any) {
    return this.http.post(this.url + "coupon/updateCouponCodesCsv", file);
  }

}
